<template>
  <div>
    <div class="ticket-card" :class="{ 'unclaimed-ticket': !isClaimed }">
      <div class="ticket-overlay" v-if="!isClaimed">
        <div class="overlay-title">{{ $t("unclaimed") }}</div>
      </div>
      <div class="ticket-body">
        <div class="ticket__event-name">
          <div class="ticket-subtitle">
            {{ $t("ticket") + ` ${index}/${total}` }}
          </div>
          <div class="ticket-title">{{ eventName }}</div>
        </div>
        <div class="ticket__event-info">
          <div class="ticket-code">
            <vue-qrcode
              :value="ticket.barcode"
              :margin="0"
              :width="190"
              :quality="1"
              errorCorrectionLevel="H"
            />
            <div class="code-text">{{ ticket.barcode }}</div>
          </div>
          <div class="ticket-data">
            <div class="data-item">
              <div class="ticket-subtitle">{{ $t("racer_name") }}</div>
              <div>{{ racerName }}</div>
            </div>
            <div class="data-item">
              <div class="ticket-subtitle">{{ $t("category") }}</div>
              <div>{{ cutRegChose }}</div>
            </div>
            <div class="data-item">
              <div class="ticket-subtitle">{{ $t("date") }}</div>
              <div>{{ eventDate }}</div>
            </div>
            <div class="data-item mobile-text-end">
              <div class="ticket-subtitle">{{ $t("start_time") }}</div>
              <div>{{ ticket.start_time }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="ticket-type">
        <div
          v-if="ticket.upass && ticket.upass.type"
          class="ticket-footer is-upass"
        >
          <img
            class="upass-title"
            src="@/assets/unbreakable.svg"
            alt="UNBREAKABLE"
          />
        </div>

        <template v-else>
          <div
            v-if="ticket.spartan_plus_member"
            class="ticket-footer plus-member"
          >
            <img class="helm-logo" src="@/assets/spartan_logo.svg" alt="logo" />
            <img class="spartan-trademark" src="@/assets/Wordmark.svg" />
            <div class="plus-icon__wrap">
              <div class="plus-icon">
                <div class="plus-line"></div>
                <div class="plus-line rotate-line"></div>
              </div>
            </div>
          </div>
          <div v-if="!ticket.spartan_plus_member" class="ticket-footer">
            <img
              class="helm-logo"
              src="@/assets/spartan_logo_grey.svg"
              alt="logo"
            />
            <img class="spartan-trademark" src="@/assets/spartan_tm_grey.svg" />
          </div>
        </template>
      </div>
    </div>
    <div class="unclaimed-notification" v-if="!isClaimed">
      <i class="material-icons">warning_amber</i>
      <div class="unclaimed-text">
        {{ $t("unclaimed_ticket_text") }}
      </div>
    </div>
  </div>
</template>
<script>
// TODO: Replace "vue-qrcode" package with component, based on  https://www.npmjs.com/package/qrcode
import VueQrcode from "vue-qrcode";
import moment from "moment";
export default {
  data: () => ({}),
  props: ["ticket", "total", "index", "eventName", "startDate"],
  computed: {
    cutRegChose() {
      let text = this.ticket.reg_choice_name;
      if (!text) return;
      let str;
      if (text.indexOf("(") !== -1) str = text.substr(0, text.indexOf("("));
      else str = text;
      return str.toLowerCase();
    },
    eventDate() {
      return moment(this.startDate).format("ddd, MMM D");
    },
    racerName() {
      if (
        this.ticket?.attendee?.first_name &&
        this.ticket?.attendee?.last_name
      ) {
        return (
          this.ticket.attendee.first_name + " " + this.ticket.attendee.last_name
        );
      } else if (this.ticket?.attendee?.email) {
        return this.ticket.attendee.email;
      } else return "";
    },
    isClaimed() {
      // return false
      return this.ticket.is_claimed;
    },
  },
  components: {
    VueQrcode,
  },
};
</script>
<style lang="scss" scoped>
.unclaimed-ticket {
  background-color: #ffffff;
  .ticket-body,
  .ticket-type {
    opacity: 0.3;
  }
  .ticket-type {
    .plus-member {
      border-color: #c4c4c4 !important;
    }
  }
}
.ticket-card {
  position: relative;
  height: 450px;
  max-width: 936px;
  width: 100%;
  margin-bottom: 22px;

  border-radius: 20px;
  display: grid;
  grid-template-columns: 1fr max-content;
  @media screen and (max-width: $mobile) {
    grid-template-columns: 1fr;
    border-radius: 16px;
    height: auto;
  }
  .ticket-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1;
    .overlay-title {
      font-size: 42px;
      font-weight: 800;
      line-height: 51px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: #cf1019;
      transform: rotate(-45deg);
    }
  }
  .ticket-body {
    padding-left: 128px;
    padding-top: 60px;
    background-color: #ffffff;
    border-radius: 20px 0 0 20px;
    @media screen and (max-width: $mobile) {
      border-radius: 0 0 16px 16px;
      padding: 20px 30px 30px 30px;
      order: 2;
    }
    .ticket-title {
      font-size: 32px;
      line-height: 39px;
      font-weight: bold;
      color: #000000;
      @media screen and (max-width: $mobile) {
        font-size: 22px;
        line-height: 27px;
      }
    }
    .ticket-subtitle {
      text-transform: capitalize;
      font-size: 14px;
      line-height: 21px;
      color: rgba(0, 0, 0, 0.5);
    }
    .ticket__event-name {
      margin-bottom: 52px;
      @media screen and (max-width: $mobile) {
        margin-bottom: 35px;
      }
      .ticket-title {
        margin-top: 4px;
      }
    }
    .ticket__event-info {
      display: flex;
      flex-direction: row;
      .ticket-code {
        margin-right: 60px;
        max-width: 190px;
        .code-text {
          margin-top: 6px;
          font-weight: 500;
          font-size: 12px;
          line-height: 15px;

          letter-spacing: 0.04em;
          text-transform: uppercase;

          color: rgba(0, 0, 0, 0.9);
          text-align: center;
        }
      }
      .ticket-data {
        display: grid;
        grid-template-columns: 185px 185px;
        row-gap: 35px;
        column-gap: 60px;
        padding: 22px 0 30px 0;
        .ticket-subtitle {
          margin-bottom: 4px;
        }
        .data-item {
          font-size: 24px;
          line-height: 25px;
          font-weight: 600;
          color: #000000;
          text-transform: capitalize;
          word-break: break-word;
        }
      }
    }
  }
  .ticket-type {
    border-radius: 0 20px 20px 0;
    @media screen and (max-width: $mobile) {
      order: 1;
      border-radius: 16px 16px 0 0;
    }
    .ticket-footer {
      position: relative;
      height: 100%;
      border-radius: inherit;
      border-right: 70px solid;
      @media screen and (max-width: $mobile) {
        border-right: none;
        border-top: 57px solid;
      }
      .helm-logo {
        position: absolute;
        bottom: 102px;
        left: 15px;
        height: 40px;
        width: 40px;
        transform: rotate(270deg);
        @media screen and (max-width: $mobile) {
          bottom: 16px;
          left: 28px;
          height: 24px;
          width: 24px;
          transform: none;
        }
      }
      .spartan-trademark {
        position: absolute;
        bottom: 204px;
        left: -45px;
        height: 40px;
        width: 160px;
        transform: rotate(270deg);
        @media screen and (max-width: $mobile) {
          bottom: 16px;
          left: 54px;
          height: 24px;
          width: 95px;
          transform: none;
        }
      }
      .upass-title {
        position: absolute;
        bottom: 200px;
        left: -50px;
        height: 55px;
        width: 172px;
        transform: rotate(270deg);
        @media screen and (max-width: $mobile) {
          bottom: 8px;
          left: calc(50% - 64px);
          height: 40px;
          width: 128px;
          transform: none;
        }
      }
    }
    .plus-member {
      border-color: #cf1019;
      .plus-icon__wrap {
        position: absolute;
        top: 116px;
        left: 24px;
        @media screen and (max-width: $mobile) {
          top: -35px;
          left: 153px;
        }
        .plus-icon {
          position: relative;
          height: 24px;
          width: 24px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          @media screen and (max-width: $mobile) {
            height: 15px;
            width: 15px;
          }
          .plus-line {
            height: 5.5px;
            width: 100%;
            background: #ffffff;
            @media screen and (max-width: $mobile) {
              height: 3px;
            }
          }
          .rotate-line {
            position: absolute;
            transform: rotate(90deg);
          }
        }
      }
    }
    .is-upass {
      border-color: #000000;
    }
  }
}
.unclaimed-notification {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 38px;
  i {
    color: #dadada;
  }
  .unclaimed-text {
    max-width: 485px;
    width: 100%;

    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
  }
}
@media screen and (max-width: 499px) {
  .ticket-card {
    .ticket-body {
      .ticket__event-info {
        flex-direction: column;
        align-items: center;
        .ticket-code {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin-right: 0;
          .code-text {
          }
        }
        .ticket-data {
          grid-template-columns: repeat(3, 1fr);
          row-gap: 17px;
          column-gap: 0;
          width: 100%;
          .ticket-subtitle {
          }
          .data-item {
            font-size: 16px;
          }
        }
        .ticket-data :first-child {
          grid-column: 1/-1;
        }
        .mobile-text-end {
          text-align: end;
        }
      }
    }
  }
  .unclaimed-notification {
    margin-bottom: 46px;
    align-items: center;
    .unclaimed-text {
      max-width: 270px;

      font-size: 14px;
      line-height: 17px;
    }
  }
}
@media print {
  .unclaimed-ticket {
    display: none;
  }
  .ticket-card {
    border: 1px solid rgba(0, 0, 0, 0.5);
    margin-bottom: 75px;
    .ticket-body {
      padding-left: 30px;
      .ticket__event-info {
        .ticket-data {
          column-gap: 30px;
        }
      }
    }
    // border-right: 70px solid #000000;
  }
  .unclaimed-notification {
    display: none;
  }
}
</style>