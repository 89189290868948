<template>
  <div class="tickets-list">
    <div class="list-header">
      <img
        src="./../../assets/spartan_logo.svg"
        alt="logo"
        @click="$router.push('/home')"
      />
      <div>
        <div class="header-button cursor-pointer" @click="printTickets">
          <i class="material-icons">print</i>
        </div>
      </div>
    </div>
    <div class="list-wrap" v-if="eventData">
      <div class="list-toolbar">
        <div class="toolbar-item cursor-pointer" @click="printTickets">
          <i class="material-icons">print</i>
        </div>
      </div>
      <div v-for="(ticket, index) in tickets" :key="index" class="card-wrap">
        <ticket-card
          :ticket="ticket"
          :total="ticketsCount"
          :index="index + 1"
          :eventName="eventData.name"
          :startDate="eventData.start_date"
        />
      </div>
    </div>
  </div>
</template>
<script>
import TicketCard from "./components/TicketCard.vue";
import { mapGetters, mapActions } from "vuex";
import { filter } from "lodash";
export default {
  data: () => ({}),
  computed: {
    ...mapGetters(["eventData"]),
    ticketsCount() {
      return this.tickets.length;
    },
    tickets() {
      return filter(this.eventData.tickets, (ticket) => {
        return ticket.status !== "Cancelled" && ticket.status !== "Transferred";
      });
    },
  },
  components: {
    TicketCard,
  },
  methods: {
    ...mapActions(["getEvent"]),
    printTickets() {
      window.print();
    },
  },
  async mounted() {
    try {
      await this.getEvent(this.$route.params.id);
    } catch (error) {
      console.log(error);
    }
  },
};
</script>
<style lang="scss" scoped>
.tickets-list {
  .list-header {
    height: 80px;
    margin: 0 70px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    img {
      height: 50px;
      width: 50px;
    }
    .header-button {
      display: none;
      height: 46px;
      width: 46px;
      border-radius: 100px;
      background-color: #ffffff;
      color: #000000;
    }
  }
  .list-toolbar {
    max-width: 936px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 30px;
    .toolbar-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      height: 70px;
      width: 70px;
      background: #ffffff;
      border-radius: 100px;
      color: #000000;
      i {
        font-size: 30px;
      }
    }
  }
  .list-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 100px;
  }
  .card-wrap {
    max-width: 936px;
    width: 100%;
  }
}

@media screen and (max-width: 499px) {
  .tickets-list {
    .list-header {
      margin: 17px 0 4px 0;
      padding: 0 16px;
      img {
        height: 46px;
        width: 46px;
      }
      .header-button {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        i {
          font-size: 28px;
        }
      }
    }
    .list-wrap {
      .list-toolbar {
        display: none;
        .toolbar-item {
          i {
          }
        }
      }
    }
  }
}

@media print {
  .tickets-list {
    display: flex;
    .list-header {
      display: none;
    }
    .list-toolbar {
      display: none;
    }
    .list-wrap {
      padding-bottom: 0;
    }
  }
}
</style>
